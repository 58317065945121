.ContactData {
    margin: 80px auto;
    width: 80%;
    position: relative;
    left: 10%;
    text-align: center;
    display: flexbox;
    border: 0.5px solid midnightblue;
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
    align-content: center;
}

/* .Input {
    display: block;
    border: solid midnightblue 0.5px;
    border-radius: 10px;
    margin: 5px auto;
    width: 90%;
    padding: 5px;
    background-color: #f0f0f0;
    font-weight: 300;
    }
*/

/* .Input[type='text'] {
    padding-left: 15px;
} */

/* @media (max-width: 600px) {
    .ContactData {
        width: 500px;
    }
} */
h1,
h2,
h4 {
    font-weight: 300;
    margin: 20px auto 5px auto;
}
.InfoMsg {
    font-size: small;
    text-align: right;
    color: midnightblue;
    margin: 0 10px;
}
