.CheckoutSummary {
    /* display: block; */
    text-align: center;
    width: 100%;
    margin: auto;
    /* align-items: center;
    align-content: center; */
}

/* @media (min-width: 600px) {
    .CheckoutSummary {
        width: 500px;
    }
} */

h2,
h3 {
    font-weight: 300;
    margin: auto;
    padding: 20px;
}
.InfoMsg {
    font-size: small;
    text-align: right;
    color: midnightblue;
    margin: 0 10px;
}

@media (min-width: 500px) and (min-height: 400px) {
    .CheckoutSummary {
        width: 350px;
        height: 300px;
    }
}

@media (min-width: 500px) and (min-height: 401px) {
    .CheckoutSummary {
        width: 450px;
        height: 400px;
    }
}

@media (min-width: 1000px) and (min-height: 700px) {
    .CheckoutSummary {
        width: 700px;
        height: 600px;
    }
}
