.BuildControls {
    /* position: relative; */
    width: auto;
    background-color: #f0f0f0;
    display: flex;
    flex-flow: column;
    align-items: center;
    /* box-shadow: 0 2px 1px #ccc; */
    margin: auto;
    padding: 10px 0;
    border: 0.5px solid;
    border-radius: 10px;
    box-sizing: border-box;
}

.OrderButton {
    margin: 30px;
    background-color: darkred;
    outline: none;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid;
    color: white;
    font-family: inherit;
    font-size: 1em;
    padding: 10px 30px;
    /* box-shadow: 2px 2px 2px #966909; */
}

.OrderButton:hover,
.OrderButton:active {
    background-color: white;
    border: 1px solid;
    color: darkred;
}

.OrderButton:disabled {
    opacity: 0.5;
    /* background-color: #c7c6c6; */
    cursor: not-allowed;
    border: 0.5px solid;
    /* color: #888888; */
}

.OrderButton:not(:disabled) {
    animation: enable 0.3s linear;
}

@keyframes enable {
    0% {
        transform: scale(1);
    }
    60% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}
