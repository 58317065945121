.Input {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    font-weight: 300;
}

.Label {
    font-weight: 400;
    font-size: x-small;
    display: block;
    margin-bottom: 8px;
    text-align: left;
    text-indent: 20px;
}

.InputElement {
    margin: auto;
    outline: none;
    border: 0.5px solid darkslategray;
    border-radius: 5px;
    background-color: #f0f0f0;
    /* font: inherit; */
    font-weight: 300;
    font-size: inherit;
    padding: 6px 25px;
    display: block;
    width: 90%;
    box-sizing: border-box;
}

.InputElement:focus {
    outline: none;
    background-color: lightskyblue;
}
.Invalid {
    border: 1px solid red;
    background-color: rgb(255, 216, 212);
}
select.InputElement {
    text-indent: 20px;
}
