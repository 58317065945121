.DrawerToggle {
  width: 40px;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  padding: 8px 0;
  box-sizing: border-box;
  cursor: pointer;
}

.DrawerToggle div {
  width: 90%;
  height: 1px;
  background-color: white;
}

@media (min-width: 500px) {
  .DrawerToggle {
    display: none;
  }
}
