.SideDrawer {
  position: fixed;
  /* width: 280px; */
  width: auto;
  max-width: 70%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 200;
  background-color: white;
  padding: 32px 16px;
  box-sizing: border-box;
  transition: transform 0.3s ease-out;
}

@media (min-width: 500px) {
  .SideDrawer {
    display: none;
  }
}

.Open {
  /* transform: translateX(0); */
  display: block;
}

.Close {
  /* transform: translateX(-100%); */
  display: none;
}

.Logo {
  height: 7%;
  margin-bottom: 30px;
  text-align: center;
}
