.Button {
    /* background-color: transparent; */
    background-color: white;
    border-radius: 5px;
    border: none;
    color: white;
    outline: none;
    cursor: pointer;
    font: inherit;
    padding: 5px 10px;
    margin: 30px auto;
    width: auto;
    box-sizing: border-box;
    position: relative;
    /* display: flex;
    align-items: center; */
    /* display: inline; */
    /* font-weight: bold; */
    font-weight: 300;
}

.Button:first-of-type {
    margin-left: 5%;
    float: left;
    /* left: 30px; */
    padding-left: 10;
}

.Button:last-of-type {
    margin-right: 5%;
    float: right;
    /* right: 30px; */
    padding-right: 10;
}

.Button:only-of-type {
    /* margin: 30px auto; */
    /* position: relative; */
    right: 30px;
    width: 120px;
}

.Success {
    background-color: green;
    color: white;
    float: right;
}

.Danger {
    background-color: rgb(250, 0, 0);
    color: white;
}

.Info {
    background-color: rgb(145, 196, 255);
    color: black;
    border: midnightblue 0.5px solid;
}

.Button:disabled {
    color: rgb(100, 100, 100);
    border: 0.5px solid black;
    background-color: rgb(173, 173, 173);
    cursor: not-allowed;
}
