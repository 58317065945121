.Modal {
    position: fixed;
    z-index: 500;
    background-color: white;
    border-radius: 10px;
    width: 75%;
    border: 1px solid #ccc;
    box-shadow: 5px 5px 10px black;
    padding: 0px 20px;
    left: 10%;
    top: 5%;
    box-sizing: border-box;
    /* overflow: auto; */
    transition: all 0.3s ease-out;
    /* align-content: center; */
}

@media (min-width: 600px) {
    .Modal {
        width: 500px;
        left: calc(50% - 250px);
    }
}
/* @media (max-height: 400px) { */
@media (max-height: 400px) and (orientation: landscape) {
    .Modal {
        overflow: scroll;
        height: 320px;
        /* height: 300px; */
    }
}
