.Auth {
    margin: 80px auto;
    width: 80%;
    position: relative;
    text-align: center;
    /* display: flexbox; */
    border: 0.5px solid midnightblue;
    border-radius: 10px;
    padding: 30px 30px 90px 30px;
    box-sizing: border-box;
    /* align-content: center; */
}

/* .Input {
    display: block;
    border: solid midnightblue 0.5px;
    border-radius: 10px;
    margin: 5px auto;
    width: 90%;
    padding: 5px;
    background-color: #f0f0f0;
    font-weight: 300;
    }
*/

/* .Input[type='text'] {
    padding-left: 15px;
} */

/* @media (max-width: 600px) {
    .Auth {
        width: 500px;
    }
} */

h1,
h2,
h4 {
    font-weight: 300;
    margin: 0px auto 5px auto;
}
.InfoMsg {
    font-size: small;
    text-align: right;
    color: midnightblue;
    margin: 0 10px;
}
.ErrorMsg {
    /* font-size: small; */
    width: 85%;
    font-weight: 500;
    text-align: center;
    color: black;
    background-color: rgb(255, 203, 203);
    border: darkred solid 0.5px;
    border-radius: 5px;
    margin-top: 20px;
}

@media (min-width: 500px) and (min-height: 400px) {
    .Auth {
        width: 350px;
        /* height: 300px; */
    }
}

@media (min-width: 500px) and (min-height: 401px) {
    .Auth {
        width: 450px;
        /* height: 400px; */
    }
}

/* @media (min-width: 1000px) and (min-height: 700px) {
    .Auth {
        width: 700px;
        height: 600px;
    }
} */
