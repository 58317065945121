.Order {
    width: 80%;
    text-align: center;
    border: 0.5px solid midnightblue;
    border-radius: 10px;
    padding: 10px;
    margin: 10px auto;
    box-sizing: border-box;
    background-color: #f0f0f0;
}
.Ingredient {
    text-transform: capitalize;
    color: rgb(145, 77, 0);
    display: inline-block;
    margin: 0 8px;
    /* border: 0.5px solid midnightblue;
    border-radius: 5px; */
    padding: 5px;
    /* background-color: rgb(199, 240, 253); */
}
.Order:hover {
    background-color: lightgrey;
}
.Selected,
.Selected:hover {
    background-color: rgb(194, 222, 255);
}
