.OrderSummary {
    text-align: center;
    list-style-position: inside;
}

.OrderSummary h2 {
    padding: 10px 5px;
    font-size: 20px;
    font-weight: 500;
}

.OrderSummary ul,
p {
    margin: 5px auto;
    padding: 5px;
    font-weight: 300;
}
